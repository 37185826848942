import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Footer from '../../components/layout/footer';
import Link from '../../components/link/link';
import SEO from '../../components/seo/seo';

import getAge from '../../utils/get-age';

import './author.css';

const Author = () => (
  <>
    <SEO title="About the author" />
    <main className="author">
      <div className="author__inner">
        <h1>About the author</h1>
        <StaticImage
          alt="Margaret Randall and Sarah Knight"
          aspectRatio={1310 / 1310}
          className="author__image"
          height={400}
          layout="constrained"
          loading="eager"
          placeholder="none"
          src="../../images/margaret-sarah.jpg"
        />
        <p>
          Margaret Randall was born in the U.K. and came to Kingston, Ontario when she was 11 years old, where
          she had to walk ten miles to school in the snow uphill both ways. She raised three children – Sarah,
          Rachel and James - whose lives she filled with joy. Like many parents she always made time to read at
          least one story to them before bed. Sometimes the published children books were not enough, so she wove
          wild tails of her own to keep them entertained.
          {' '}
          <cite>The Sockpicker</cite>
          {' '}
          is the first of them to be publicly released. She is currently retired and living in Woodstock, Ontario.
        </p>
        <p>
          Sarah Knight is Margaret&apos;s oldest child with a talent for art that has enriched the lives (and walls) of
          her family and friends. She has a
          {' '}
          {getAge('2001/07/01')}
          -year-old son who still insists on having
          {' '}
          <cite>The Sockpicker</cite>
          {' '}
          read to him every night before bed… just kidding. While she paints and draws in her spare time, she spends
          most of her days helping people to heal themselves and their relationships with each other and the earth.
          You can read more at her website
          {' '}
          <Link
            linkType="plain"
            to="https://www.humhealing.com"
          >
            www.humhealing.com
          </Link>
          . She lives in Kingston, Ontario.
        </p>
      </div>
      <Footer links={['contact', 'home', 'shop']} />
    </main>
  </>
);

export default Author;
